<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" class="border mb-50" :items="items" :total-rows="totalCount" :fields="fields" searchable>
      <template #actions="{ item }">
        <div class="d-flex gap-3">
          <app-icon-button icon="EyeIcon" variant="gradient-danger" @click="showDetailSidebar(item)" />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import showDetails from "./showDetails.vue";

export default {
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    items() {
      return this.$store.getters?.observations?.data || [];
    },
    totalCount() {
      return this.$store.getters?.observations?.total || 0;
    },
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    visit() {
      return this.$store.getters.visit;
    },
    fields() {
      return [
        { key: "branch.name", label: "Sicil" },
        { key: "title", label: "Başlık" },
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  destroyed() {
    this.$store.commit("setObservations", []);
  },
  methods: {
    showDetailSidebar(item) {
      this.$axios
        .get("/actions/observations", { params: { _id: item._id }, loading: "table" })
        .then((res) => {
          this.$showAppSidebar(res.data.title, showDetails, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      const query = this.visit
        ? {
            visit: this.visit._id,
          }
        : {
            company: this.company,
          };
      this.$axios
        .get("/actions/observations/paginated", {
          params: {
            ...this.filters,
            ...query,
            populates: !this.selectedBranch ? JSON.stringify(["branch"]) : undefined,
          },
          loading: "table",
        })
        .then((result) => {
          this.$store.commit("setObservations", result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
