import { render, staticRenderFns } from "./ImageGalleryModal.vue?vue&type=template&id=bf5a6efe"
import script from "./ImageGalleryModal.vue?vue&type=script&lang=js"
export * from "./ImageGalleryModal.vue?vue&type=script&lang=js"
import style0 from "./ImageGalleryModal.vue?vue&type=style&index=0&id=bf5a6efe&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports